<template>
  <v-container class="py-0" fluid style="height: 100vh; background-color: white">
    <v-row style="height: 100vh">
      <v-col cols="12">
        <BackArrow/>
        <v-row align="start" justify="center">
          <v-row class="mx-5 mt-9">
            <p style="margin-bottom: 0; padding-top: 24px" class="ma-3 mb-0 generic-title">
              Atualizar Senha
            </p>
            <v-row style="padding-top: 12px">
              <v-col cols="12">
                <p class="ma-3">Para trocar sua senha,
                  você precisa sair da sua conta e clicar em <b>"Atualizar senha"</b> no login.</p>
              </v-col>
            </v-row>
          </v-row>
        </v-row>
      </v-col>
    <v-col cols="12">
      <v-row>
        <v-timeline
        dense
        id="timeline"
        >
          <v-timeline-item
          fill-dot
          color="pink"
          icon="1"
          class="mr-9 align-center generic-text timeline-item mr-1"
          ><template v-slot:icon>
            <span class="generic-title" style="color: white; font-weight: bold">1</span>
          </template>Sair da sua conta.
          </v-timeline-item>

          <v-timeline-item
              hide-dot
          >
          </v-timeline-item>
          <v-timeline-item
              right
              fill-dot
              color="pink"
              icon="2"
              class="mr-9 align-center generic-text timeline-item mr-1"
          ><template v-slot:icon>
            <span class="generic-title" style="color: white; font-weight: bold">2</span>
          </template>
            No login, clicar em <b>"Atualizar senha".</b>
          </v-timeline-item>
          <v-timeline-item
              hide-dot
              icon="2"
          >
          </v-timeline-item>
          <v-timeline-item
          fill-dot
          color="pink"
          icon="3"
          class="mr-9 align-center generic-text timeline-item"
          ><template v-slot:icon>
            <span class="generic-title" style="color: white; font-weight: bold">3</span>
          </template>
            Altere sua senha e acesse o aplicativo novamente.
          </v-timeline-item>
        </v-timeline>
      </v-row>
    </v-col>
      <v-col
          class="pb-10"
        align-self="end"
        cols="12"
      >
        <generic-btn
          color="primary"
          :btn-props="{ block: true, large: true}"
          :on-click="() => $router.go(-1)"
        >ok
        </generic-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import GenericBtn from "../../components/generic-btn";
import BackArrow from "@/components/covid/back-arrow";

export default {
  data: () => ({

  }),
  components: {
    GenericBtn,
    BackArrow

  },

}
</script>
<style scoped>
#timeline::before{
  top: 55px;
  height: calc(100% - 110px)
}

.timeline-item{
  font-family: sans-serif;
}
</style>